import React from "react";

import FlexRow from "../UI/FlexRow/FlexRow";
import ImageBox from "./ImageBox";
import ImageText from "./ImageText";
import SingleBox from "./SingleBox";
import FaceImage from "./FaceImage";
import viewportCalculator from "../../lib/ViewportCalculator";

import { ethers } from "ethers";
import { formatDistanceToNow } from 'date-fns'

import classes from "./MainApp.module.css";

const mintBtnTxt = (supplyData) => {
  console.log(supplyData)
  
  if(!supplyData) {
    return "loading...";
  }

  if(supplyData.currentToken === 3338) {
    return 'Sold Out'
  }

  if(supplyData.tokenSaleState === 0) {
    return 'Minting Paused';
  }
  else if(supplyData.tokenSaleState === 2 || supplyData.tokenSaleState === 3) {
    return 'Mint';
  }
  else {
    if(supplyData.nonEthereumBrowser) {
      return "Minting unavailable"
    }
    return 'Minting Soon';
  }
};

const formatSubText = (supplyData) => {
  if(!supplyData) {
    return "loading...";
  }

  if(supplyData.currentToken === 3338) {
    return 'All tokens have been sold'
  }

  if(supplyData.tokenSaleState === 0) {
    return 'Please stand by...';
  }
  else if(supplyData.tokenSaleState === 2) {
    return 'Private Sale In Progress'
    return null; //`${(supplyData.maxSupply - 5) - (supplyData.currentToken - 5)} available`
  }
  else if(supplyData.tokenSaleState === 3) {
    return `${supplyData.currentToken - 5}/${supplyData.maxSupply - 5}`
  }
  else {
    if(supplyData.nonEthereumBrowser) {
      return "Non-Ethereum browser"
    }
    return 'Stay Tuned';
  }
};

const formatHeadingTxt = (supplyData) => {
  if(!supplyData) {
    return "loading...";
  }

  if(supplyData.currentToken === 3338) {
    return null;
  }

  if(supplyData.tokenSaleState === 0) {
    return null; //'Minting Paused - check back soon!';
  }
  else if(supplyData.tokenSaleState === 2) {
    return null;//'Private Sale In Progress';
  }
  else if(supplyData.tokenSaleState === 3) {
    return 'Public Sale In Progress';
  }
  else {
    return null; //'Minting disabled.';
  }
};

const MainApp = ({ screenWidth, supplyData, mintModalBtnHandler }) => {
  return (
    <main className={classes.main}>
      <FlexRow>
        <ImageBox imgSrc={require("../../assets/images/MainApp/HH4.jpg")} />
        <ImageBox imgSrc={require("../../assets/images/MainApp/center.jpg")}>
          <div className={classes["h1-container"]}>
            <h1 className={classes["desktop-h1"]} style={{ fontSize: viewportCalculator(screenWidth, 1.8) }}>
              Audio-driven
              <br/>
              digital collectibles
            </h1>
          </div>
        </ImageBox>
        <ImageBox imgSrc={require("../../assets/images/MainApp/HH5.jpg")} />
      </FlexRow>
      <FlexRow id="mint">
        <ImageBox imgSrc={require("../../assets/images/MainApp/MINT.jpg")} />
        <ImageBox imgSrc={require("../../assets/images/MainApp/BLANK1.jpg")}>
          <ImageText
            imgSrc={require("../../assets/images/MainApp/mint_cube.gif")}
            text={formatHeadingTxt(supplyData)}
            subText={formatSubText(supplyData)}
            btnText={mintBtnTxt(supplyData)}
            screenWidth={screenWidth}
            btnClickHander={mintModalBtnHandler}
          />
        </ImageBox>
        <ImageBox imgSrc={require("../../assets/images/MainApp/S6.jpg")} />
      </FlexRow>
      <FlexRow>
        <ImageBox imgSrc={require("../../assets/images/MainApp/KB4.jpg")} />
        <ImageBox imgSrc={require("../../assets/images/MainApp/BLANK1.jpg")}>
          <div>
            {/* <div className={classes["first-custom-container"]}> */}
            <div >
              <h2
                style={{
                  fontSize: viewportCalculator(screenWidth, 1.25),
                  marginRight: viewportCalculator(screenWidth, 3),
                }}
              >
                Series Zero
              </h2>
              <br />
              <p style={{ fontSize: viewportCalculator(screenWidth, 1) }}>
                {/* Our genesis collection of 3,333 3D
                <br />
                animated, audio-driven, generative
                <br />
                collectibles. The first project of its
                <br />
                kind; each 3Q collectible acts as your
                <br />
                key to the 3Q ecosystem, in which
                <br />
                music, design and innovation beat a
                <br />
                path towards scarcity, connectivity
                <br />
                and reward.
                <br />
                <br />
                In isolating individual audio stems
                <br />
                and conceptualising what each
                <br />
                sound looks like, we create
                <br />
                highly imaginative mechanisms
                <br />
                which are visually representative
                <br />
                of the sounds that drive their
                <br />
                movement. */}
                A genesis collection of 3,333 unique 3D EQs.
                <br />
                3Q takes the layering principle of generative
                <br />
                PFP tech and applies it to music and 3D
                <br />
                animation, creating the first NFT project of
                <br />
                its kind.
                <br />
                <br />
                To bring Series Zero to life, 30 individual
                <br />
                audio stems were first isolated, then used as
                <br />
                inspiration for the intricate 3D
                <br />
                representations that followed. The data
                <br />
                from each audio file was then used to drive
                <br />
                its corresponding 3D mechanism on the
                <br />
                single face of a cube. Finally, 5 random cube
                <br />
                faces are combined and layered to create each
                <br />
                audio-visually unique 3Q collectible.
              </p>
            </div>
          </div>
        </ImageBox>
        <ImageBox imgSrc={require("../../assets/images/MainApp/S3.jpg")} />
      </FlexRow>
      {/* <FlexRow id="roadmap">
        <SingleBox imgSrc={require("../../assets/images/MainApp/ROADMAP_NUM.jpg")} />
      </FlexRow> */}
      <FlexRow id="learn">
        <ImageBox imgSrc={require("../../assets/images/MainApp/P2.jpg")} />
        
        <ImageBox imgSrc={require("../../assets/images/MainApp/BLANK1.jpg")}>
          <div>
            <div className={classes["second-custom-container"]}>
              <img
                className={classes["decoration-image"]}
                src={require("../../assets/images/MainApp/bullet.png")}
                alt=""
              />
              <p style={{ fontSize: viewportCalculator(screenWidth, 0.9) }}>
                Each Series Zero 3Q collectible is audio-visually
                <br />
                unique and programmatically generated from
                <br />
                traits such as Bass, Kick Drum, HH & Melody.
                <br />
                Traits vary in complexity, and each OM (Original
                <br />
                Mint) 3Q collectible falls into one of the
                <br />
                following categories:
              </p>
            </div>
            <div
              className={classes.levels}
              style={{
                fontSize: viewportCalculator(screenWidth, 0.9),
                margin: `${viewportCalculator(screenWidth, 3)} 0 ${viewportCalculator(screenWidth, 2)}`,
              }}
            >
              <div>
                <div>
                  <span style={{ color: "#000" }}> CLEAN </span>-
                  <span style={{ color: "#afff7a" }}> COMPLEX </span>-
                  <span style={{ color: "#45d7ff" }}> HYPNOTIC </span>
                </div>
                <div>
                  <span style={{ color: "#b300ad" }}> EXTREME </span>-
                  <span style={{ color: "#ff0000" }}> INSANE </span>-
                  <span style={{ color: "#e7eb00" }}> ULTIMATE </span>
                </div>
              </div>
            </div>
            <div className={classes["second-custom-container"]}>
              <img
                className={classes["decoration-image"]}
                src={require("../../assets/images/MainApp/bullet.png")}
                alt=""
              />
              <p style={{ fontSize: viewportCalculator(screenWidth, 0.9) }}>
                Series Zero 3Q collectibles are minted directly from
                <br />
                our website with a maximum purchase of 6 per
                <br />
                tx. They are stored as ERC-721 tokens on the
                <br />
                Ethereum blockchain.
                <br />
                <br />
                Verified contract source can be viewed on
                <br />
                Etherscan at {" "}
                <a className={classes["provenance-record"]}
                  href="https://etherscan.io/address/0x609054A45966B66D3b38f789350DF8E573ab2d8B#code"
                  target="_blank"
                  rel="noreferrer">
                  Series Zero
                </a>.
                Provenance information
                <br />
                at {" "}
                <a className={classes["provenance-record"]} href="https://serieszeroprovenance.3q.club" target="_blank" rel="noreferrer">
                  Provenance Record
                </a>.
              </p>
            </div>
          </div>
        </ImageBox>
        <ImageBox imgSrc={require("../../assets/images/MainApp/kb6.jpg")} />
      </FlexRow>
      {/* <FlexRow id="team">
        <ImageBox imgSrc={require("../../assets/images/MainApp/TEAM.jpg")} />
        <ImageBox imgSrc={require("../../assets/images/MainApp/S2.jpg")} />
        <ImageBox imgSrc={require("../../assets/images/MainApp/BLANK1.jpg")}>
          <div>
            <div className={classes["second-custom-container"]}>
              <img
                className={classes["decoration-image"]}
                src={require("../../assets/images/MainApp/bullet.png")}
                alt=""
              />
              <p style={{ fontSize: viewportCalculator(screenWidth, 0.9) }}>
                Want to upgrade your 3Q collectible? Our
                <br />
                initial deflationary mechanics launch post-OM
                <br />
                reveal. Send 3 OM collectibles into the BURNER
                <br />
                to create 1 SUPREME 3Q collectible. Forged from
                <br />
                the rarest elements of the 3 you burn and
                <br />
                pushed to another level of design; SUPREME 3Q
                <br />
                collectible holders will be eligible to win
                <br />
                the genesis 3d printed physical 3Q pendant,
                <br />
                signifying the launch of our physical merch.
              </p>
            </div>

            <div className={classes["second-custom-container"]}>
              <img
                className={classes["decoration-image"]}
                src={require("../../assets/images/MainApp/bullet.png")}
                alt=""
              />
              <p style={{ fontSize: viewportCalculator(screenWidth, 0.9) }}>
                Verified contract source can be viewed on Etherscan at {" "}
                <br/>
                <a className={classes["provenance-record"]}
                  href="https://etherscan.io/address/0x609054A45966B66D3b38f789350DF8E573ab2d8B#code"
                  target="_blank"
                  rel="noreferrer">
                  Series Zero
                </a>.
                Provenance information at {" "}
                <br/>
                <a className={classes["provenance-record"]} href="https://serieszeroprovenance.3q.club" target="_blank" rel="noreferrer">
                  Provenance Record
                </a>.
              </p>
            </div>
          </div>
        </ImageBox>
      </FlexRow> */}
      {/* <FlexRow>
        <ImageBox imgSrc={require("../../assets/images/MainApp/BLANK1.jpg")}>
          <FaceImage
            title="PAT PRICE"
            subtitle="Creation & Community"
            text="Pat is a singer-songwriter, screenwriter and creative entrepreneur whose professional life has spanned the film, music, mental health & hospitality industries. A restless leader; he heads up the Series Zero marketing arm and 3Q.CLUB community, all whilst blessing the Twitter Space waves with his ukulele anthems."
            imgSrc={require("../../assets/images/MainApp/PAT.png")}
            screenWidth={screenWidth}
          />
        </ImageBox>
        <ImageBox imgSrc={require("../../assets/images/MainApp/BLANK1.jpg")}>
          <FaceImage
            title="JOE CONWAY"
            subtitle="Creation & Visual Art"
            text="A 3D visionary known for outstanding design in the hospitality sector, Joe creates objects and spaces with a plethora of intentions from bespoke glassware and cutlery to 6-person, carbon-fibre beds and three meter high Carrara marble statues. For 3Q, Average Joe utilises his skills in 3D tech and advance surface modelling to create the visual language in his first foray into NFTs."
            imgSrc={require("../../assets/images/MainApp/JOE.png")}
            screenWidth={screenWidth}
          />
        </ImageBox>
        <ImageBox imgSrc={require("../../assets/images/MainApp/KB2.jpg")} />
      </FlexRow> */}
      {/* <FlexRow>
        <ImageBox imgSrc={require("../../assets/images/MainApp/HH1.jpg")} />
        <ImageBox imgSrc={require("../../assets/images/MainApp/BLANK1.jpg")}>
          <FaceImage
            title="@APHEXYURI"
            subtitle="Technology"
            text="Yuri has a diverse software engineering background and steers all things tech for 3Q.CLUB. He has designed, built and rolled out infrastructure and systems for various household-name game developer shops and have been tinkering with blockchain development since 2017! We let him out of his coding cage on occasion to mingle with humanity."
            imgSrc={require("../../assets/images/MainApp/YURI.png")}
            screenWidth={screenWidth}
          />
        </ImageBox>
        <ImageBox imgSrc={require("../../assets/images/MainApp/BLANK1.jpg")}>
          <FaceImage
            title="WILLIE DEVINE"
            subtitle="Tokenomics"
            text="Willie (Ratchet Man) has been working in London’s banking industry for more than 12 years and brings to the team a penchant for beautiful spreadsheets and statistics. As well as taking care of financial duties for 3Q, he also devises the systems that ensure audio/visual permutations and rarity scorings are understood."
            imgSrc={require("../../assets/images/MainApp/WILLIE.png")}
            screenWidth={screenWidth}
          />
        </ImageBox>
      </FlexRow> */}
      {/* <FlexRow>
        <ImageBox imgSrc={require("../../assets/images/MainApp/S1.jpg")} />
        <ImageBox imgSrc={require("../../assets/images/MainApp/BLANK1.jpg")}>
          <FaceImage
            title="NICK FRENCH"
            subtitle="Audio Art - Series Zero"
            text="Nick is a music producer, songwriter and engineer hailing out of Essex, UK. Nick has worked alongside some of the UK’s most prized rappers, singers & producers; his most recent success include the likes of Central Cee, Clavish, Backroad Gee, Ktrap, Kwengface. A man completely obsessed with music and all things sound, he produced the audio stems for the 3Q Genesis cubes."
            imgSrc={require("../../assets/images/MainApp/NICK.png")}
            screenWidth={screenWidth}
          />
        </ImageBox>
        <ImageBox imgSrc={require("../../assets/images/MainApp/P1.jpg")} />
      </FlexRow> */}
    </main>
  );
};

export default MainApp;
