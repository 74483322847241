import React from "react";

import classes from "./FlexRow.module.css";

const FlexRow = (props) => {
  return (
    <div id={props.id} className={classes.flex}>
      {props.children}
    </div>
  );
};

export default FlexRow;
