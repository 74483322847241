import React from "react";

import classes from "./Social.module.css";

const SOCIAL_ITEMS = [
  // {
  //   name: "Discord",
  //   linkTo: "https://discord.com/invite/HxbSejSvdJ",
  // },
  {
    name: "Twitter",
    linkTo: "https://twitter.com/3qCollectibles",
  },
  {
    name: "OpenSea",
    linkTo: "https://opensea.io/collection/3q-series-zero",
  },
];

const Social = () => {
  return (
    <ul className={classes["social-list"]}>
      {SOCIAL_ITEMS.map((socialItem, idx) => (
        <li key={idx}>
          <a href={socialItem.linkTo} target="_blank" rel="noreferrer">
            <img src={require(`../../assets/images/Social/${socialItem.name}.png`)} alt={socialItem} />
          </a>
        </li>
      ))}
    </ul>
  );
};

export default Social;
