import React, { useState } from "react";

import Navigation from "./Navigations";
import Social from "./Social";
import HamburgerButton from "../UI/HamburgerButton/HamburgerButton";

import classes from "./Header.module.css";
import LogoImg from "../../assets/images/Logo/Logo.png";

const Header = (props) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const menuToggleHandler = () => {
    setMenuIsOpen((prevState) => !prevState);
  };

  const menuCloseHandler = () => {
    setMenuIsOpen(false);
  };

  return (
    <header className={classes.header}>
      <a href="/">
        <img className={classes.logoImg} src={LogoImg} alt="Website's Logo" />
      </a>
      <Navigation
        navItems={props.navItems}
        menuIsOpen={menuIsOpen}
        onLinkClick={menuCloseHandler}/>
      <Social />
      <HamburgerButton menuIsOpen={menuIsOpen} onClick={menuToggleHandler} />
    </header>
  );
};

export default Header;
