import React from "react";

import FlexRow from "../UI/FlexRow/FlexRow";
import FaceImage from "./FaceImage";
import ImageBox from "./ImageBox";
import ImageText from "./ImageText";
import viewportCalculator from "../../lib/ViewportCalculator";

import { ethers } from "ethers";
import { formatDistanceToNow } from 'date-fns'

import classes from "./MobileApp.module.css";

const mintBtnTxt = (supplyData) => {
  if(!supplyData) {
    return "loading...";
  }

  if(supplyData.currentToken === 3338) {
    return 'Sold Out'
  }

  if(supplyData.tokenSaleState === 0) {
    return 'Minting Paused';
  }
  else if(supplyData.tokenSaleState === 2 || supplyData.tokenSaleState === 3) {
    return 'Mint';
  }
  else {
    if(supplyData.nonEthereumBrowser) {
      return "Minting unavailable"
    }
    return 'Minting Soon';
  }
};

const formatSubText = (supplyData) => {
  if(!supplyData) {
    return "loading...";
  }

  if(supplyData.currentToken === 3338) {
    return 'All tokens have been sold'
  }

  if(supplyData.tokenSaleState === 0) {
    return 'Please stand by...';
  }
  else if(supplyData.tokenSaleState === 2) {
    return 'Private Sale In Progress'
    return null; //`${(supplyData.maxSupply - 5) - (supplyData.currentToken - 5)} available`
  }
  else if(supplyData.tokenSaleState === 3) {
    return `${supplyData.currentToken - 5}/${supplyData.maxSupply - 5}`
  }
  else {
    if(supplyData.nonEthereumBrowser) {
      return "Non-Ethereum browser"
    }
    return 'Stay Tuned';
  }
};

const formatHeadingTxt = (supplyData) => {
  if(!supplyData) {
    return "loading...";
  }

  if(supplyData.currentToken === 3338) {
    return null;
  }

  if(supplyData.tokenSaleState === 0) {
    return null; //'Minting Paused - check back soon!';
  }
  else if(supplyData.tokenSaleState === 2) {
    return null;//'Private Sale In Progress';
  }
  else if(supplyData.tokenSaleState === 3) {
    return 'Public Sale In Progress';
  }
  else {
    return null; //'Minting disabled.';
  }
};

const MobileApp = ({ screenWidth, supplyData, mintModalBtnHandler }) => {
  // console.log(screenWidth);
  return (
    <main>
      <FlexRow>
        <ImageBox imgSrc={require("../../assets/images/MainApp/S6.jpg")}>
          <h1 className={classes["mobile-h1"]} style={{ fontSize: viewportCalculator(screenWidth, 5) }}>
              Audio-driven
              <br/>
              digital collectibles
          </h1>
        </ImageBox>
      </FlexRow>
      <FlexRow id="mint">
        <ImageBox imgSrc={require("../../assets/images/MainApp/BLANK1.jpg")}>
          <ImageText
            imgSrc={require("../../assets/images/MainApp/mint_cube.gif")}
            text={formatHeadingTxt(supplyData)}
            subText={formatSubText(supplyData)}
            btnText={mintBtnTxt(supplyData)}
            screenWidth={screenWidth}
            btnClickHander={mintModalBtnHandler}
          />
        </ImageBox>
      </FlexRow>
      <div className={classes["row-custom-container"]}>
        <img src={require("../../assets/images/MainApp/P2.jpg")} alt="" />
        <img src={require("../../assets/images/MainApp/kb6.jpg")} alt="" />
      </div>
      <FlexRow>
        <ImageBox imgSrc={require("../../assets/images/MainApp/BLANK1.jpg")}>
          <div className={classes["first-comp-container"]}>
            <div className={classes["first-mobile-custom-container"]}>
              <h2 style={{ fontSize: viewportCalculator(screenWidth, 5) }}>
                Series Zero
              </h2>
              <p style={{ fontSize: viewportCalculator(screenWidth, 3) }}>
                A genesis collection of 3,333 unique 3D EQs. 3Q takes the layering principle of generative PFP tech and applies it to music and 3D animation, creating the first NFT project of its kind. 
                <br />
                <br />
                To bring Series Zero to life, 30 individual audio stems were first isolated, then used as inspiration for the intricate 3D representations that followed. The data from each audio file was then used to drive its corresponding 3D mechanism on the single face of a cube. Finally, 5 random cube faces are combined and layered to create each audio-visually unique 3Q collectible.
              </p>
            </div>
          </div>
        </ImageBox>
      </FlexRow>
      {/* <div className={classes["row-custom-container"]}>
        <img src={require("../../assets/images/MainApp/S3.jpg")} alt="" />
        <img src={require("../../assets/images/MainApp/ROADMAP_ARROW.jpg")} alt="" />
      </div> */}
      {/* <FlexRow id="roadmap">
        <ImageBox imgSrc={require("../../assets/images/MainApp/ROADMAP_NUM.jpg")} />
      </FlexRow> */}
      <div className={classes["row-custom-container"]}>
        <img src={require("../../assets/images/MainApp/HH5.jpg")} alt="" />
        <img src={require("../../assets/images/MainApp/M3.jpg")} alt="" />
      </div>
      <FlexRow id="learn">
        <ImageBox imgSrc={require("../../assets/images/MainApp/BLANK1.jpg")}>
          <div>
            <div className={classes["second-custom-container"]}>
              <img
                className={classes["decoration-image"]}
                src={require("../../assets/images/MainApp/bullet.png")}
                alt=""
              />
              <p style={{ fontSize: viewportCalculator(screenWidth, 3.2) }}>
                Each Series Zero 3Q collectible is audio-visually
                <br />
                unique and programmatically generated from
                <br />
                traits such as Bass, Kick Drum, HH & Melody.
                <br />
                Traits vary in complexity, and each OM (Original
                <br />
                Mint) 3Q collectible falls into one of the
                <br />
                following categories:
              </p>
            </div>
            <div className={classes.levels} style={{ fontSize: viewportCalculator(screenWidth, 3.2) }}>
              <div>
                <div>
                  <span style={{ color: "#000" }}> CLEAN </span>-
                  <span style={{ color: "#afff7a" }}> COMPLEX </span>-
                  <span style={{ color: "#45d7ff" }}> HYPNOTIC </span>
                </div>
                <div>
                  <span style={{ color: "#b300ad" }}> EXTREME </span>-
                  <span style={{ color: "#ff0000" }}> INSANE </span>-
                  <span style={{ color: "#e7eb00" }}> ULTIMATE </span>
                </div>
              </div>
            </div>
            <div className={classes["second-custom-container"]}>
              <img
                className={classes["decoration-image"]}
                src={require("../../assets/images/MainApp/bullet.png")}
                alt=""
              />
              <p style={{ fontSize: viewportCalculator(screenWidth, 3.2) }}>
                Series Zero 3Q collectibles are minted directly
                <br />
                from our website with a maximum purchase
                <br />
                of 6 per tx. They are stored as ERC-721 tokens
                <br />
                on the Ethereum blockchain.
                <br />
                <br />
                Verified contract source can be viewed on
                <br />
                Etherscan at {" "}
                <a className={classes["provenance-record"]}
                  href="https://etherscan.io/address/0x609054A45966B66D3b38f789350DF8E573ab2d8B#code"
                  target="_blank"
                  rel="noreferrer">
                  Series Zero
                </a>.
                Provenance
                <br />
                information at {" "}
                <a className={classes["provenance-record"]} href="https://serieszeroprovenance.3q.club" target="_blank" rel="noreferrer">
                  Provenance Record
                </a>.
              </p>
            </div>
          </div>
        </ImageBox>
      </FlexRow>
      <div className={classes["row-custom-container"]}>
        <img src={require("../../assets/images/MainApp/M3.jpg")} alt="" />
        <img src={require("../../assets/images/MainApp/S3.jpg")} alt="" />
      </div>
      {/* <FlexRow>
        <ImageBox imgSrc={require("../../assets/images/MainApp/BLANK1.jpg")}>
          <div>
            <div className={classes["second-custom-container"]}>
              <img
                className={classes["decoration-image"]}
                src={require("../../assets/images/MainApp/bullet.png")}
                alt=""
              />
              <p style={{ fontSize: viewportCalculator(screenWidth, 3.2) }}>
                Want to upgrade your 3Q collectible? Our
                <br />
                initial deflationary mechanics launch
                <br />
                post-OM reveal. Send 3 OM collectibles
                <br />
                into the BURNER to create 1 SUPREME 3Q
                <br />
                collectible. Forged from the rarest
                <br />
                elements of the 3 you burn and pushed
                <br />
                to another level of design; SUPREME 3Q
                <br />
                collectible holders will be eligible to
                <br />
                win the genesis 3d printed physical
                <br />
                3Q pendant, signifying the launch
                <br />
                of our physical merch.
              </p>
            </div>

            <div className={classes["second-custom-container"]}>
              <img
                className={classes["decoration-image"]}
                src={require("../../assets/images/MainApp/bullet.png")}
                alt=""
              />
              <p style={{ fontSize: viewportCalculator(screenWidth, 3.2) }}>
                Verified contract source can be viewed on Etherscan at {" "}
                <br/>
                <a className={classes["provenance-record"]}
                  href="https://etherscan.io/address/0x609054A45966B66D3b38f789350DF8E573ab2d8B#code"
                  target="_blank"
                  rel="noreferrer">
                  Series Zero
                </a>.
                Provenance information at {" "}
                <br/>
                <a className={classes["provenance-record"]} href="https://serieszeroprovenance.3q.club" target="_blank" rel="noreferrer">
                  Provenance Record
                </a>.
              </p>
            </div>
          </div>
        </ImageBox>
      </FlexRow> */}
      {/* <div className={classes["row-custom-container"]} id="team">
        <img src={require("../../assets/images/MainApp/HH2.jpg")} alt="" />
        <img src={require("../../assets/images/MainApp/TEAM.jpg")} alt="" />
      </div> */}
      {/* <FlexRow>
        <ImageBox imgSrc={require("../../assets/images/MainApp/BLANK1.jpg")}>
          <FaceImage
            title="PAT PRICE"
            subtitle="Creation & Community"
            text="Pat is a singer-songwriter, screenwriter and creative entrepreneur whose professional life has spanned the film, music, mental health & hospitality industries. A restless leader; he heads up the Series Zero marketing arm and 3Q.CLUB community, all whilst blessing the Twitter Space waves with his ukulele anthems."
            imgSrc={require("../../assets/images/MainApp/PAT.png")}
            screenWidth={screenWidth}
          />
        </ImageBox>
      </FlexRow> */}
      {/* <FlexRow>
        <ImageBox imgSrc={require("../../assets/images/MainApp/BLANK1.jpg")}>
          <FaceImage
            title="JOE CONWAY"
            subtitle="Creation & Visual Art"
            text="A 3D visionary known for outstanding design in the hospitality sector, Joe creates objects and spaces with a plethora of intentions from 6-person, carbon-fibre beds to three meter high Carrara marble statues. For 3Q, Average Joe utilises his skills in 3D tech and advance surface modelling to create the visual language in his first foray into NFTs."
            imgSrc={require("../../assets/images/MainApp/JOE.png")}
            screenWidth={screenWidth}
          />
        </ImageBox>
      </FlexRow> */}
      {/* <FlexRow>
        <ImageBox imgSrc={require("../../assets/images/MainApp/BLANK1.jpg")}>
          <FaceImage
            title="@APHEXYURI"
            subtitle="Technology"
            text="Yuri has a diverse software engineering background and steers all things tech for 3Q.CLUB. He has designed, built and rolled out infrastructure and systems for various household-name game developer shops and have been tinkering with blockchain development since 2017! We let him out of his coding cage on occasion to mingle with humanity."
            imgSrc={require("../../assets/images/MainApp/YURI.png")}
            screenWidth={screenWidth}
          />
        </ImageBox>
      </FlexRow> */}
      {/* <FlexRow>
        <ImageBox imgSrc={require("../../assets/images/MainApp/BLANK1.jpg")}>
          <FaceImage
            title="WILLIE DEVINE"
            subtitle="Tokenomics"
            text="Willie (Ratchet Man) has been working in London’s banking industry for more than 12 years and brings to the team a penchant for beautiful spreadsheets and statistics. As well taking care of financial duties for 3Q, he also devises the systems that ensure audio/visual permutations and rarity scorings are understood."
            imgSrc={require("../../assets/images/MainApp/WILLIE.png")}
            screenWidth={screenWidth}
          />
        </ImageBox>
      </FlexRow> */}
      {/* <FlexRow>
        <ImageBox imgSrc={require("../../assets/images/MainApp/BLANK1.jpg")}>
          <FaceImage
            title="NICK FRENCH"
            subtitle="Audio Art - Series Zero"
            text="Nick is a music producer, songwriter and engineer hailing out of Essex, UK. Nick has worked alongside some of the UK’s most prized rappers, singers & producers; his most recent success include the likes of Central Cee, Clavish, Backroad Gee, Ktrap, Kwengface. A man completely obsessed with music and all things sound, he produced the audio stems for the 3Q Genesis cubes."
            imgSrc={require("../../assets/images/MainApp/NICK.png")}
            screenWidth={screenWidth}
          />
        </ImageBox>
      </FlexRow> */}
      {/* <div className={classes["row-custom-container"]}>
        <img src={require("../../assets/images/MainApp/HH1.jpg")} alt="" />
        <img src={require("../../assets/images/MainApp/P1.jpg")} alt="" />
      </div> */}
    </main>
  );
};

export default MobileApp;
