import React from "react";

import classes from "./ImageBox.module.css";

const ImageBox = (props) => {
  return (
    <div id={props.id} className={classes["image-box"]} style={{ backgroundImage: `url(${props.imgSrc})` }}>
      {props.children}
    </div>
  );
};

export default ImageBox;
