import ReactGA from 'react-ga'

import {
    REACT_GA_ENABLED
} from '../constants.js'

export function trackGA(category, action) {
    if(REACT_GA_ENABLED) {
        ReactGA.event({
            category: category,
            action: action
        })
    }
}