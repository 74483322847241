import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
// import Divider from '@mui/material/Divider';
// import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

import { ethers } from "ethers";
import { format } from 'date-fns'

const PreSaleMintDialog = ({
    supplyData,
    web3Signer,
    connectWalletBtnHandler,
    mintBtnHandler,
    presaleAllocation
}) => {

    const intoMessage = () => {
        return(
            <div>
                {
                    presaleAllocation > 0 ?
                        <p>We are grateful for your continued support and we're pleased to offer you a discounted sale price during our private mint phase.</p>
                    :
                        <p>Welcome to our private sale! During this phase we celebrate you, our community and invite exclusive members to mint their whitelist allocations.</p>
                }
            </div>
        )
    };

    const priceAndAllocationMessage = () => {
        if(presaleAllocation === 0) {
            return(
                <p>You do not have a pre-sale mint allocation. Please ensure you are connected with the right wallet.</p>
            )
        }
        else if(presaleAllocation > 0) {
            let price = ethers.utils.formatEther(supplyData.mintPrice, 'ether')
            return(
                <p>{`You have an allocation of ${presaleAllocation} mints at a price of ${price} ETH each.`} Select the number of tokens you wish to mint.</p>
            )
        }
    };

    const connectControls = () => {
        if(web3Signer == null && window.ethereum) {
            return(
                <Button variant="outlined" onClick={connectWalletBtnHandler}>
                    Connect Wallet
                </Button>
            )
        }
        else {
            return(
                <div>
                    <Typography variant="h6" component="div" color='orange' align='center'>Warning: Web3 Capabilities</Typography>
                    <Typography variant="caption" component="div" align='center'>Please visit this site from a Web3 capable browser.</Typography>
                    <Typography variant="caption" component="div" align='center'>We strongly recommend <a href='https://metamask.io/' target='_blank' rel='noreferrer'>MetaMask</a>.</Typography>
                </div>
            )
        }
    }
    
    const mintControls = () => {
        if(presaleAllocation > 0) {
            return(
                <ButtonGroup variant="contained" aria-label="outlined primary button group">
                    {
                        presaleAllocation > 0 ?
                            <Button variant="outlined" onClick={() => {mintBtnHandler(1)}}>Mint 1</Button>
                        :
                            null
                    }

                    {
                        presaleAllocation > 1 ?
                            <Button variant="outlined" onClick={() => {mintBtnHandler(2)}}>Mint 2</Button>
                        :
                            null
                    }
                    
                    {
                        presaleAllocation > 2 ?
                            <Button variant="outlined" onClick={() => {mintBtnHandler(3)}}>Mint 3</Button>
                        :
                            null
                    }
                </ButtonGroup>
            )
        }
        else {
            return null;
        }
    };

    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
        >
            <Grid item p={2}>
                <Typography variant="body1" component="div" gutterBottom align='justify'>
                    {intoMessage()}
                </Typography>
            </Grid>

            {
                web3Signer != null ?
                    <Grid item p={2}>
                        <Typography variant="caption" component="div" gutterBottom align='justify'>
                            {priceAndAllocationMessage()}
                        </Typography>
                    </Grid>
                :
                    <Grid item p={2}>
                        <Typography variant="caption" component="div" gutterBottom>
                            Connect your wallet to get started!
                        </Typography>
                    </Grid>
            }

            <Grid item p={2}>
                {
                    web3Signer == null ?
                        connectControls()
                    :
                        mintControls()
                }
            </Grid>
            
        </Grid>
    )
};

export default PreSaleMintDialog;