import React from "react";

import classes from "./HamburgerButton.module.css";

const HamburgerButton = (props) => {
  let btnClasses = classes["hamburger-btn"];
  if (props.menuIsOpen) btnClasses += ` ${classes.active}`;

  return (
    <button className={btnClasses} onClick={props.onClick}>
      <span className={classes.bar}></span>
      <span className={classes.bar}></span>
      <span className={classes.bar}></span>
    </button>
  );
};

export default HamburgerButton;
