import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
// import Divider from '@mui/material/Divider';
// import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

import { ethers } from "ethers";
import { format } from 'date-fns'

const PublicSaleMintDialog = ({ supplyData, web3Signer, connectWalletBtnHandler, mintBtnHandler }) => {

    const intoMessage = () => {
        if(supplyData) {
            return(
                <p>Welcome to the Series Zero Public Sale!</p>
            )
        }
    };

    const priceAndAllocationMessage = () => {
        let price = ethers.utils.formatEther(supplyData.mintPrice, 'ether')
        return(
            <div>
                <p>{`Select the number of tokens you wish to mint at ${price} ETH per token.`}</p>
            </div>
        )
    };

    const connectControls = () => {
        if(web3Signer == null && window.ethereum) {
            return(
                <Button variant="outlined" onClick={connectWalletBtnHandler}>
                    Connect Wallet
                </Button>
            )
        }
        else {
            return(
                <div>
                    <Typography variant="h6" component="div" color='orange' align='center'>Warning: Web3 Capabilities</Typography>
                    <Typography variant="caption" component="div" align='center'>Please visit this site from a Web3 capable browser.</Typography>
                    <Typography variant="caption" component="div" align='center'>We strongly recommend <a href='https://metamask.io/' target='_blank'rel='noreferrer'>MetaMask</a>.</Typography>
                </div>
            )
        }
    }
    
    const mintControls = () => {
        if(supplyData) {
            return(
                <ButtonGroup variant="contained" aria-label="outlined primary button group">
                    <Button variant="outlined" onClick={() => {mintBtnHandler(1)}}>Mint 1</Button>
                    <Button variant="outlined" onClick={() => {mintBtnHandler(3)}}>Mint 3</Button>
                    <Button variant="outlined" onClick={() => {mintBtnHandler(6)}}>Mint 6</Button>
                </ButtonGroup>
            )
        }
    };

    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
        >
            <Grid item p={2}>
                <Typography variant="body1" component="div" gutterBottom>
                    {intoMessage()}
                </Typography>
            </Grid>

            {
                web3Signer != null ?
                    <Grid item p={2}>
                        <Typography variant="caption" component="div" gutterBottom>
                            {priceAndAllocationMessage()}
                        </Typography>
                    </Grid>
                :
                    <Grid item p={2}>
                        <Typography variant="caption" component="div" gutterBottom>
                            Connect your wallet to get started!
                        </Typography>
                    </Grid>
            }

            <Grid item p={2}>
                {
                    web3Signer == null ?
                        connectControls()
                    :
                        mintControls()
                }
            </Grid>
            
        </Grid>
    )
};

export default PublicSaleMintDialog;