import React from "react";

import classes from "./Navigation.module.css";

const Navigation = (props) => {
  let navigationClasses = classes.navigation;
  if (props.menuIsOpen) navigationClasses += ` ${classes.active}`;

  return (
    <nav className={navigationClasses}>
      <ul className={classes["navigation-list"]}>
        {props.navItems.map((navItem, idx) => (
          <li key={idx}>
            {navItem.title === "Twitter" || navItem.title === "Discord" || navItem.title === "OpenSea" ? (
              <a href={navItem.link} target="_blank" rel="noreferrer" onClick={props.onLinkClick}>
                {navItem.title}
              </a>
            ) : (
              <a href={navItem.link} onClick={props.onLinkClick}>
                {navItem.title}
              </a>
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Navigation;
