import React from "react";

// import EmailIcon from "@mui/icons-material/Email";
import Social from "../Header/Social";

import classes from "./Footer.module.css";

const Footer = () => {
  return (
    <footer className={classes.footer}>
      <form
        className={classes.form}
        onSubmit={(event) => {
          event.preventDefault();
        }}
      >
        {/* <p>Mailing List</p>
        <div className={classes.formInputContainer}>
          <input placeholder="Email" />
          <button type="submit">
            <EmailIcon sx={{ color: "white" }} />
          </button>
        </div> */}
      </form>
      <img className={classes.footerLogo} src={require("../../assets/images/Logo/Footer_logo.png")} alt="Footer logo" />
      <div className={classes.socialAndConditions}>
        <Social />
        {/* <p className={classes.topSpacer}>&#169; 3Q Collectibles Ltd</p> */}
        <p className={classes.conditions}>
          <a href="https://3q.club/IP.pdf" target='_blank' rel='noreferrer'>Terms &amp; Conditions</a>
        </p>
        {/* <p className={classes.conditions}>
          <a href="/">Privacy Policy</a>
        </p> */}
      </div>
    </footer>
  );
};

export default Footer;
