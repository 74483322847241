import React from "react";

import viewportCalculator from "../../lib/ViewportCalculator";
import classes from "./ImageText.module.css";

// ImageText component requires three props :
// 1: imgSrc
// 2: text
// 3: btnText
// 4: screenWidth

const ImageText = ({ imgSrc, text, subText, btnText, screenWidth, btnClickHander }) => {
  return (
    <div className={classes["image-text__container"]}>
      {imgSrc && <img src={imgSrc} alt="" />}
      <div className={classes["image-text__content"]}>
        <h1 className={classes.text} style={{ fontSize: viewportCalculator(screenWidth, screenWidth > 750 ? 1.4 : 5) }}>
          {text}
        </h1>
        
        {btnText && (
          <button
            onClick={ btnClickHander }
            style={{
              fontSize: viewportCalculator(screenWidth, screenWidth > 750 ? 1.3 : 5),
              padding: `${viewportCalculator(screenWidth, screenWidth > 750 ? 0.5 : 2)} ${viewportCalculator(
                screenWidth,
                screenWidth > 750 ? 4 : 18
              )}`,
            }}
          >
            {btnText}
          </button>
        )}
        {
          subText ?
            <h2 className={classes.text_sub} style={{ fontSize: viewportCalculator(screenWidth, screenWidth > 750 ? 1.2 : 3) }}>
              {subText}
            </h2>
          : 
            null
        }
      </div>
    </div>
  );
};

export default ImageText;
