import React, { useEffect, useState } from 'react';
import { Fragment } from "react/cjs/react.production.min";
import useWindowDimensions from "./hooks/use-window-dimensions";

import Header from "./components/Header/Header";
import MainApp from "./components/Main/MainApp";
import MobileApp from "./components/Main/MobileApp";
import Footer from "./components/Footer/Footer";

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'

// import AuctionDialogContent from './components/UI/AuctionDialogContent';
import PreSaleMintDialog from './components/UI/PreSaleMintDialog';
import PublicSaleMintDialog from './components/UI/PublicSaleMintDialog';

import { ethers } from "ethers";

import ReactGA from "react-ga";

import {
  REACT_GA_ENABLED,
  SUPPLY_DATA_API_ENDPOINT,
  SERIES_ZERO_CONTRACT_ADDRESS,
} from "./constants";

import { trackGA } from './utils/miscUtils'

const ercAbi = require('./contracts//ABI_ThreeQSeriesZeroERC721.json')

const axios = require('axios');

const BN = require('bn.js');

let web3SeriesZeroContract;
let web3Address;

function App() {
  const { width: screenWidth } = useWindowDimensions();
  const [modalOpen, setModalOpen] = useState(false);
  const [web3Provider, setWeb3Provider] = useState(null);
  const [web3Signer, setWeb3Signer] = useState(null);
  // const [web3Address, setWeb3Address] = useState(null);
  
  const [toastMessage, setToastMessage] = useState('');
  const [toastType, setToastType] = useState('warning');
  const [toastDuration, setToastDuration] = useState(6000);
  const [toastOpen, setToastOpen] = useState(false);

  const [supplyData, setSupplyData] = useState(null);
  // const [seriesZeroContract, setSeriesZeroContract] = useState(null);
  const [presaleAllocation, setPresaleAllocation] = useState(0);

  const getSupplyDataFromApi = () => {
    return new Promise((resolve, reject) => {
      try {
        axios.get(SUPPLY_DATA_API_ENDPOINT)
        .then(function (response) {
          let apiSupplyDataParsed = {
            currentToken: parseInt(response.data.currentToken),
            isTokenHolder: response.data.isTokenHolder,
            maxSupply: parseInt(response.data.maxSupply),
            mintPrice: new BN(response.data.mintPrice),
            tokenSaleState: parseInt(response.data.tokenSaleState)
          }
          console.log('apiSupplyDataParsed', apiSupplyDataParsed);
          setSupplyData(apiSupplyDataParsed);
          resolve(apiSupplyDataParsed);
        })
        .catch(function (error) {
          console.log('http get error:', error);
          reject(error);
        })
      }
      catch(e) {
        reject(e);
      }
    });
  };

  useEffect(() => {
    async function init() {
      if (REACT_GA_ENABLED) {
        ReactGA.initialize("UA-218735756-1");
        ReactGA.pageview("home");
      }

      let tmpSupplyData = await getSupplyDataFromApi();
      setSupplyData(tmpSupplyData);

      if(tmpSupplyData.errorType != null) {
        trackGA('API-Error', 'API-Error-SupplyData');
        return;
      }

      trackGA('API', 'API-SupplyData');

      if(!window.ethereum) {
        console.log('Non-Ethereum enabled browser');
        trackGA('Web3', 'Web3-Incapable-Browser');

        setSupplyData({nonEthereumBrowser: true});

        setToastMessage('Ethereum not detected. Please use an Ethereum enabled browser such as Chrome with Metamask.');
        setToastType('error');
        setToastDuration(15000);
        setToastOpen(true);
        return;
      }

      let tmpProvider = await new ethers.providers.Web3Provider(window.ethereum, 'any')
      // console.log('tmpProvider', tmpProvider)
      setWeb3Provider(tmpProvider);
      
      web3SeriesZeroContract = new ethers.Contract(SERIES_ZERO_CONTRACT_ADDRESS, ercAbi, tmpProvider)
      // console.log('web3SeriesZeroContract', web3SeriesZeroContract)
      // setSeriesZeroContract(web3SeriesZeroContract);

      web3SeriesZeroContract.on('TokenMinted', (tokenIndex, minter, saleStatus) => {
        console.log(`TokenMinted event - tokenIndex: ${tokenIndex}, saleStatus: ${saleStatus}`);
        refreshSupplyAndAllocation();
      });

      let supplyData;
      if(web3Address) {
        supplyData = await web3SeriesZeroContract.getSupplyData({from: web3Address});  
      }
      else {
        supplyData = await web3SeriesZeroContract.getSupplyData({from:'0x000000000000000000000000000000000000dead'});  
      }
      
      let supplyDataParsed = {
        currentToken: supplyData._currentToken.toNumber(),
        isTokenHolder: supplyData._isTokenHolder,
        maxSupply: supplyData._maxSupply.toNumber(),
        mintPrice: supplyData._mintPrice,
        tokenSaleState: supplyData._tokenSaleState
      }
      // console.log('supplyDataParsed', supplyDataParsed);
      setSupplyData(supplyDataParsed);
    }

    init();
  }, []);

  const refreshSupplyAndAllocation = async () => {
    // console.log('refreshPresaleAllocation', web3Address);
    if(web3SeriesZeroContract) {
      if(web3Address) {
        let tmpPresaleAllocation = await web3SeriesZeroContract.presaleParticipants(web3Address);
        setPresaleAllocation(tmpPresaleAllocation.toNumber())
      }

      let supplyData;
      if(web3Address) {
        supplyData = await web3SeriesZeroContract.getSupplyData({from: web3Address});  
      }
      else {
        supplyData = await web3SeriesZeroContract.getSupplyData({from:'0x000000000000000000000000000000000000dead'});  
      }
      let supplyDataParsed = {
        currentToken: supplyData._currentToken.toNumber(),
        isTokenHolder: supplyData._isTokenHolder,
        maxSupply: supplyData._maxSupply.toNumber(),
        mintPrice: supplyData._mintPrice,
        tokenSaleState: supplyData._tokenSaleState
      }
      // console.log('supplyDataParsed', supplyDataParsed);
      setSupplyData(supplyDataParsed);
    }
  };
  
  const handleModalClose = () => {
    setModalOpen(false);
  };

  const mintModalBtnHandler = async (event) => {
    if(!supplyData) {
      return;
    }

    if(supplyData.currentToken === 3338) {
      return;
    }
    
    if(supplyData.tokenSaleState === 2 || supplyData.tokenSaleState === 3) {
      trackGA('UX', 'UX-Show-Mint-Modal');
      setModalOpen(true);
    }
    else {
      // no-op
    }
  };

  const connectWalletBtnHandler = async () => {
    trackGA('UX', 'UX-Connect-Wallet');

    await web3Provider.send("eth_requestAccounts", []);
    let signer = await web3Provider.getSigner();

    web3Address = await signer.getAddress();
    // setWeb3Address(tmpWeb3Address);
    // console.log('tmpWeb3Address', tmpWeb3Address)

    setWeb3Signer(signer);

    if(supplyData.tokenSaleState === 2) {
      let tmpPresaleAllocation = await web3SeriesZeroContract.presaleParticipants(web3Address);
      setPresaleAllocation(tmpPresaleAllocation.toNumber())
      // console.log('tmpPresaleAllocation', tmpPresaleAllocation);
    }

    trackGA('Web3', 'Web3-Connect-Wallet');
  };

  const toastCloseHandler = () => {
    setToastOpen(false);
  };

  const mintBtnHandler = async (numTokens) => {
    if(supplyData.tokenSaleState === 2) {
      trackGA('UX', `UX-PrivateMint-${numTokens}`);
    }
    else if(supplyData.tokenSaleState === 3) {
      trackGA('UX', `UX-PublicMint-${numTokens}`);
    }

    const contract = web3SeriesZeroContract.connect(web3Signer);
    let tx;

    // console.log('value', supplyData.mintPrice * numTokens)

    let value = supplyData.mintPrice * numTokens;

    try {
      if(supplyData.tokenSaleState === 2) {
        await contract.presaleMint(numTokens, {value: value.toString()});
      }
      else if(supplyData.tokenSaleState === 3) {
        await contract.publicMint(numTokens, {value: value.toString()});
      }
    }
    catch(err) {
      if(err.error && err.error.data) {
        console.log("err.error.data.message", err.error.data.code);

        setToastMessage('err.error.message');
        setToastType('error');
        setToastDuration(8000);
        setToastOpen(true);

        trackGA('Web3', `PublicMint-Failed-Revert-${err.error.data.code}`);
      }
      else {
        console.log("err.message:", err.message)

        setToastMessage(err.message);
        setToastType('error');
        setToastDuration(8000);
        setToastOpen(true);

        if(err.message.includes("denied")) {
          trackGA('Web3', 'PublicMint-Failed-Tx-Denied');
        }
        else {
          trackGA('Web3', err.code ? `PublicMint-Failed-${err.code}` : 'PublicMint-Failed-Unknown');
        }
      }

      return;
    }

    // console.log('tx', tx);
    setToastMessage('Transaction submitted. Please allow some time for processing on the Ethereum Blockchain.');
    setToastType('success');
    setToastDuration(15000);
    setToastOpen(true);

    if(supplyData.tokenSaleState === 2) {
      trackGA('Web3', `Web3-PrivateMint-${numTokens}`);
    }
    else if(supplyData.tokenSaleState === 3) {
      trackGA('Web3', `web3-PublicMint-${numTokens}`);
    }
  };

  const mintDialogContent = () => {
    
    if(supplyData && supplyData.tokenSaleState === 2) {
      return (
        <PreSaleMintDialog
          supplyData={supplyData}
          web3Signer={web3Signer}
          connectWalletBtnHandler={connectWalletBtnHandler}
          mintBtnHandler={mintBtnHandler}
          presaleAllocation={presaleAllocation}/>
      )
    }
    else if(supplyData && supplyData.tokenSaleState === 3) {
      return (
        <PublicSaleMintDialog
          supplyData={supplyData}
          web3Signer={web3Signer}
          connectWalletBtnHandler={connectWalletBtnHandler}
          mintBtnHandler={mintBtnHandler}/>
      )
    }
  }

  let App = <MainApp
    screenWidth={screenWidth}
    supplyData={supplyData}
    mintModalBtnHandler={mintModalBtnHandler}
  />

  let navItems = [
    {
      title: "MINT",
      link: "#mint",
    },
    // {
    //   title: "ROADMAP",
    //   link: "#roadmap",
    // },
    {
      title: "LEARN",
      link: "#learn",
    },
    // {
    //   title: "TEAM",
    //   link: "#team",
    // },
  ];

  if (screenWidth <= 750) {
    App = <MobileApp
      screenWidth={screenWidth}
      supplyData={supplyData}
      mintModalBtnHandler={mintModalBtnHandler}
    />;

    navItems.push({
      title: "Twitter",
      link: "https://twitter.com/3qCollectibles",
    });

    // navItems.push({
    //   title: "Discord",
    //   link: "https://discord.com/invite/HxbSejSvdJ",
    // });
    navItems.push({
      title: "OpenSea",
      link: "https://opensea.io/collection/3q-series-zero",
    });
  }
  return (
    <Fragment>
      <Header navItems={navItems} />
      {App}
      <Footer />
      <Dialog
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {
            supplyData && parseInt(supplyData.tokenSaleState) === 2 ?
              'Series Zero Private Sale'
            :
              'Series Zero Public Sale'
          }
          
        </DialogTitle>
        <DialogContent>
          {mintDialogContent()}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        severity="warning"
        open={toastOpen}
        autoHideDuration={toastDuration}
        onClose={toastCloseHandler}
        message={toastMessage}
      >
        <Alert onClose={toastCloseHandler} severity={toastType} sx={{ width: '100%' }} variant="filled">
          {toastMessage}
        </Alert>
      </Snackbar>
    </Fragment>
  );
}

export default App;
